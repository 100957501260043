import React, { useEffect, useState } from 'react';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import FormInput from '../shared/FormControl/Input';

const QuantityAndDiscountForm = ({ onNext, data: data_ }) => {
  const [error, setError] = useState('');
  const [data, setData] = useState(JSON.parse(JSON.stringify(data_)));
  const [discountDuration, setDiscountDuration] = useState();

  const hasDiscountIneligibleProducts =
    !data.coreSubscription.discountEligible ||
    data.paidAddOns.some(addOn => !addOn.discountEligible);

  const handleQuantityChange = (key, value, index) => {
    setData(prevState => {
      const newState = { ...prevState };

      if (typeof index === 'number') {
        newState[key][index].quantity = value;
      } else {
        newState[key].quantity = value;
      }

      return newState;
    });
  };

  const handleDiscountAmountInput = event => {
    const numberValue = parseInt(event.target.value.replace('%', ''), 10);
    const displayedValue = `${numberValue}%`;

    if (Number.isNaN(numberValue)) {
      event.target.value = '';

      setData(prevState => {
        return {
          ...prevState,
          discountType: data_.discountType,
        };
      });
    } else {
      event.target.value = displayedValue;
      event.target.setSelectionRange(
        displayedValue.length - 1,
        displayedValue.length - 1
      );

      setData(prevState => {
        return {
          ...prevState,
          discountType: {
            ...prevState.discountType,
            amount: parseInt(event.target.value, 10) / 100,
          },
        };
      });
    }
  };

  useEffect(() => {
    setData(prevState => {
      return {
        ...prevState,
        discountType: {
          ...prevState.discountType,
          duration: discountDuration,
        },
      };
    });
  }, [discountDuration]);

  const handleNext = () => {
    if (
      data.coreSubscription.quantity &&
      data.paidAddOns.every(addOn => !!addOn.quantity) &&
      data.freeAddOns.every(addOn => !!addOn.quantity) &&
      (data.discountType.heading !== 'None'
        ? !!data.discountType.amount
        : true) &&
      (data.discountType.heading === 'Monthly'
        ? !!data.discountType.duration
        : true)
    ) {
      setError('');
      onNext(data);
    } else {
      setError('Please complete all the required fields!');
    }
  };

  return (
    <div className="form">
      <p className="form-kicker">Step Two</p>
      <h2 className="form-title">Quantity & Discount Selection</h2>

      <div className="form-content">
        <div className="line-items">
          <div className="header">
            <h3>Product</h3>
            <h3>Quantity</h3>
          </div>

          <div className="item">
            {data.coreSubscription.heading}
            <FormInput
              min={1}
              onChange={event =>
                handleQuantityChange(
                  'coreSubscription',
                  parseInt(event.target.value, 10)
                )
              }
              onWheel={event => event.preventDefault()}
              type="number"
              value={data.coreSubscription.quantity}
            />
          </div>

          {data.paidAddOns.map((product, productIndex) => (
            <div className="item" key={product.heading}>
              {product.heading}
              <FormInput
                min={1}
                onChange={event =>
                  handleQuantityChange(
                    'paidAddOns',
                    parseInt(event.target.value, 10),
                    productIndex
                  )
                }
                onWheel={event => event.preventDefault()}
                type="number"
                value={product.quantity}
              />
            </div>
          ))}

          {data.freeAddOns.map((product, productIndex) => (
            <div className="item" key={product.heading}>
              {product.heading.replace(/ \(.*$/, '')}
              <FormInput
                min={1}
                onChange={event =>
                  handleQuantityChange(
                    'freeAddOns',
                    parseInt(event.target.value, 10),
                    productIndex
                  )
                }
                onWheel={event => event.preventDefault()}
                type="number"
                value={product.quantity}
              />
            </div>
          ))}
        </div>

        {data.discountType.heading !== 'None' && (
          <>
            <div>
              <label htmlFor="discount-amount">
                Discount Amount (%)
                <abbr title="required" className="required">
                  *
                </abbr>
              </label>
              <FormInput
                id="discount-amount"
                info={
                  hasDiscountIneligibleProducts ? (
                    <div className="align-left">
                      <p>Does not apply to the following products:</p>
                      <ul>
                        {!data.coreSubscription.discountEligible && (
                          <li>{data.coreSubscription.heading}</li>
                        )}
                        {data.paidAddOns
                          .filter(addOn => !addOn.discountEligible)
                          .map(addOn => (
                            <li key={addOn.heading}>{addOn.heading}</li>
                          ))}
                      </ul>
                    </div>
                  ) : (
                    undefined
                  )
                }
                name="discount-amount"
                onInput={handleDiscountAmountInput}
                placeholder="Enter percentage (%)"
              />
            </div>

            {data.discountType.heading === 'Monthly' && (
              <div>
                <label htmlFor="discount-duration">
                  Discount Duration (months)
                  <abbr title="required" className="required">
                    *
                  </abbr>
                </label>
                <FormInput
                  id="discount-duration"
                  placeholder="Enter number of months"
                  max={12}
                  name="discount-duration"
                  onChange={event =>
                    event.target.value > 12
                      ? setDiscountDuration(12)
                      : setDiscountDuration(event.target.value)
                  }
                  onWheel={event => event.preventDefault()}
                  type="number"
                  value={discountDuration}
                />
              </div>
            )}
          </>
        )}

        {error && <p className="text-danger fs-16">{error}</p>}

        <CallToAction
          className="w-100"
          customHandleClick={handleNext}
          size="lg"
          value="Next"
          variant="secondary"
        />
      </div>
    </div>
  );
};

export default QuantityAndDiscountForm;
